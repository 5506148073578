.login-company {
  display: inline-block;
  //text-align: right;
  // padding-top: 16px;
  // padding-left: 50px;
  li {
    width: 100%;
    color: #000 !important;
    padding-right: 0 !important;
    span {
      width: 100%;
      text-align: right;
    }
  }
  .login-menu {
    margin-bottom: 13px;
    position: absolute;
    bottom: 0px;
    height: 52px;
  }
}

#companypopover {
  .MuiPopover-paper {
    padding: 10px;
    width: 200px;
    span {
      width: 100%;
      display: inline-block;
      margin: 5px 0 5px 0;
      font-size: 14px;
    }
    span:hover {
      cursor: pointer;
      color: rgb(13, 135, 221);
    }
  }
}

.passenger-dialog {
  min-width: 400px;
}

.booking-history-detail-modal {
  .MuiPaper-root.MuiAccordion-root.Mui-expanded {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  // .MuiAccordionSummary-root {
  //   display: inline-flex;
  // }
  .MuiSvgIcon-root {
    color: #20a8d8 !important;
  }
  .detail-region {
    display: block;

    h3 {
      font-family: "Roboto", Myanmar3 !important;
      background: #20a8d8;
      padding: 6px 0 10px 10px;
      font-size: 15px;
      color: #fff;
      width: 70%;
      margin: 0 0 10px 0;
    }

    span {
      font-size: 13px;
      font-family: "Roboto", Myanmar3 !important;
      display: inline-block;
    }
    .titlename {
      color: #6f6b6b;
    }

    .MuiGrid-item {
      margin: 5px 0;
    }
  }
}

#b2b-last-reservation {
  .company-select {
    border: 1px solid #e3e3e3;
    background: #fff;
    padding: 7px 15px;
    display: inline-block;
    font-size: 14px;
    width: 80%;
    border-radius: 5px;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    float: left;
  }

  .filter-region {
    display: inline-block;
    width: 100%;
    background-color: #efefef;
    padding: 20px 0;
    font-family: "Roboto", Myanmar3 !important;

    @media (max-width: 600px) {
      padding-left: 10px;
    }

    .id-box {
      padding: 7px 20px 7px 10px;
      border: 1px solid #e3e3e3;
      font-size: 13px;
      color: gray;
      background-image: url("https://cdn.flymya.com/search_icon.png");
      background-size: 15px 15px;
      background-repeat: no-repeat;
      background-position: 95% 50%;
      outline: 0;
      border-radius: 5px;
      margin-left: 5px;

      @media (max-width: 600px) {
        margin-left: 0px;
        margin-bottom: 15px;
      }
    }
    .insiderDashboard {
      color: #00aeef;
      cursor: pointer;
      position: absolute;
      left: 35%;
      right: 43%;
      padding-top: 8px;

      @media (max-width: 600px) {
        position: unset;
      }
    }
    .date-label {
      font-size: 14px;
      margin-top: 7px;
      display: inline-block;
      font-family: "Roboto", Myanmar3 !important;

      @media (max-width: 600px) {
        padding-bottom: 10px;
        padding-top: 5px;
      }
    }
    .DateRangePicker {
      width: 95%;
      .DateRangePickerInput__withBorder {
        border: 0 !important;
        background-color: none !important;
      }
      .DateInput {
        background: #efefef !important;
        width: 50% !important;
      }
      .DateInput_input {
        padding: 3px 15px;
        font-size: 15px;
        width: 75%;
        border: 1px solid #e3e3e3;
      }
    }
    .btn-apply {
      background: #20a8d8;
      color: #fff;
      padding: 2px 10px;
      margin-top: 3px;
    }
  }

  .resTable table {
    font-family: "Roboto", Myanmar3 !important;
    border: 1px solid #eee;

    .loading {
      margin: 40px;
    }
    a {
      color: #20a8d8;
    }
    .no-message {
      text-align: left;
      padding-left: 20px;
      h3 {
        font-size: 16px;
        font-family: "Roboto", Myanmar3 !important;
        width: 100%;
        margin-top: 0px;
      }
      p span {
        color: #20a8d8;
      }
    }

    .MuiButton-root.info_tooltip:hover {
      background-color: #fafafa !important;
    }
    .info_tooltip {
      display: inline-block;
      text-align: left;
      min-width: auto !important;
    }
    img.info {
      width: 15px;
    }
    .MuiButton-sizeSmall {
      border: 1px solid #20a8d8;
      padding: 1px 10px;
      font-size: 12px;
      min-width: auto;
      background: #20a8d8;
      color: #fff;
    }
    .MuiButton-sizeSmall:hover {
      background: #20a8d8;
      color: #fff;
    }
    .eticket-btn {
      border: 1px solid green;
      padding: 1px 10px;
      font-size: 12px;
      min-width: auto;
      background: green;
      color: #fff;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      line-height: 1.75;
      border-radius: 4px;
      cursor: pointer;
    }
    .eticket-btn:hover {
      background: green;
      color: #fff;
    }
    .eticket-loading {
      width: 15px !important;
      height: 15px !important;
      color: #fff;
    }
    .eticket-loading-wrapper {
      border: 1px solid green;
      padding: 7px 30px;
      min-width: 50px;
      background: green;
    }
  }

  .container {
    overflow-x: auto;
    width: 100%;
    margin: auto;
    max-width: 100%;

    tr {
      height: 40px !important;
    }

    th {
      max-width: 125px;
      min-width: 125px;
      padding: 0 !important;
      overflow-x: auto;
      white-space: nowrap;
      text-align: center !important;
      font-weight: bold;
    }

    td {
      height: 40px !important;
      padding: 5px !important;
      text-align: center !important;
    }
  }

  .count {
    color: #20a8d8;
    cursor: pointer;
    font-size: 13px;
  }
  .count:hover {
    text-decoration: underline;
  }
  .product-detail {
    font-size: 12px;
    font-family: "Roboto", Myanmar3 !important;
  }

  .payment-type {
    font-size: 12px;
    font-family: "Roboto", Myanmar3 !important;
  }
  .price-detail {
    font-size: 12px;
    font-family: "Roboto", Myanmar3 !important;
    background: #e3e3e3;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .pagi-region {
    display: flex;
    margin-top: 20px;

    select {
      display: inline-flex;
      margin-left: 10px;
      height: 32px;
      padding: 0px 10px;
      outline: none;
      border: 1px solid #e3e3e3;
    }

    span {
      display: inline-flex;
      line-height: 30px;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  ul.common-pagination {
    display: inline-flex;
    padding-left: 1px;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;

    li.active {
      a {
        color: #fff;
        background-color: #20a8d8;
        border-color: #20a8d8;
      }
    }

    li.disabled {
      a {
        color: #73818f;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #e3e3e3;
      }
    }

    li {
      a {
        position: relative;
        display: block;
        padding: 5px 10px;
        margin-left: -1px;
        line-height: 1.25;
        color: #20a8d8;
        background-color: #fff;
        border: 1px solid #e3e3e3;
        cursor: pointer;
      }
    }
  }
}

.DateRangePicker_picker {
  left: 0 !important;
}
.DayPicker_portal__horizontal {
  top: 45% !important;
  left: 47% !important;
  transform: translate(0%, -25%);
}

.transaction-region {
  margin: 20px 0px;
  display: inline-block;
  border-top: 1px dashed #eee;
}
#b2b-transaction-history {
  .company-select {
    border: 1px solid #e3e3e3;
    background: #fff;
    padding: 7px 15px;
    display: inline-block;
    font-size: 14px;
    width: 75%;
    border-radius: 5px;
  }

  h3 {
    float: left;
  }

  .filter-region {
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
    font-family: "Roboto", Myanmar3 !important;

    .id-box {
      padding: 7px 20px 7px 10px;
      width: 75%;
      border: 1px solid #e3e3e3;
      font-size: 13px;
      color: gray;
      outline: 0;
      border-radius: 5px;
    }
    .filter-label {
      font-size: 14px;
      margin-top: 7px;
      display: inline-block;
      font-family: "Roboto", Myanmar3 !important;
    }
    .btn-apply {
      background: #20a8d8;
      color: #fff;
      padding: 2px 10px;
      margin-top: 3px;
    }
  }

  .tranTable table {
    width: 100%;
    font-family: "Roboto", Myanmar3 !important;
    border: 1px solid #eee;

    .loading {
      margin: 40px;
    }

    .no-message {
      text-align: left;
      padding-left: 20px;
      h3 {
        font-size: 16px;
        font-family: "Roboto", Myanmar3 !important;
        width: 100%;
        margin-top: 0px;
      }
      p span,
      a {
        color: #20a8d8;
      }
    }

    .MuiButton-root.info_tooltip:hover {
      background-color: #fafafa !important;
    }
    .info_tooltip {
      display: inline-block;
      text-align: left;
      min-width: auto !important;
    }
    img.info {
      width: 15px;
    }
  }

  .container {
    overflow-x: auto;
    width: 100% !important;

    tr {
      height: 40px !important;
    }

    th {
      max-width: 125px;
      min-width: 125px;
      padding: 0 !important;
      overflow-x: auto;
      white-space: nowrap;
      text-align: center !important;
    }

    td {
      height: 40px !important;
      padding: 5px !important;
      text-align: center !important;
    }
  }

  .pagi-region {
    display: flex;
    margin-top: 20px;

    select {
      display: inline-flex;
      margin-left: 10px;
      height: 32px;
      padding: 0px 10px;
      outline: none;
      border: 1px solid #e3e3e3;
    }

    span {
      display: inline-flex;
      line-height: 30px;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  ul.common-pagination {
    display: inline-flex;
    padding-left: 1px;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0;

    li.active {
      a {
        color: #fff;
        background-color: #20a8d8;
        border-color: #20a8d8;
      }
    }

    li.disabled {
      a {
        color: #73818f;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #e3e3e3;
      }
    }

    li {
      a {
        position: relative;
        display: block;
        padding: 5px 10px;
        margin-left: -1px;
        line-height: 1.25;
        color: #20a8d8;
        background-color: #fff;
        border: 1px solid #e3e3e3;
        cursor: pointer;
      }
    }
  }
}

#bookingHistoryMobileDetail {
  margin-bottom: 12px;
  background-color: #fff;
  height: 100vh;
  padding: 10px;
  .bookingListContainer {
    border: 1px solid #838383;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    .bookingListWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .bookingList {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .titleText {
          color: #000;
          opacity: 0.47;
          font-size: 13px;
        }
        .bodyText {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
    .productCountContainer {
      padding: 15px;
      background-color: #f7f7f7;
      margin: 10px;
      .productCount {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .plus {
          font-size: 20px;
          color: #00aeef;
        }
      }
    }
  }
}

#bookingHistoryMobile {
  margin-bottom: 12px;

  .bookingListContainer {
    border: 1px solid #838383;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .bookingListWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .bookingList {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .titleText {
          color: #000;
          opacity: 0.47;
          font-size: 13px;
        }
        .bodyText {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  #b2b-last-reservation .filter-region .id-box {
    width: 280px;
  }
}
.status-filter-select {
  background-color: white;
  padding: 4px 24px;
  height: 31px;
  margin: 0 0 0 12px;

  @media (max-width: 780px) {
    margin: 20px 0;
  }
}

@media (max-width: 1024px) {
  .login-company {
    .login-menu {
      bottom: 22px;
    }
  }
}

@media (max-width: 780px) {
  .login-company {
    .login-menu {
      bottom: 5px;
      right: 10px;
    }
  }
}
