@media (max-width: 780px) {
  .covidInfo {
    display: none;
  }
  .bgviber.covidInfo {
    display: initial;
  }

  body {
    background-color: #f4f3f4;
  }

  .homeInApp {
    #gorgias-chat-container {
      display: none;
    }
  }

  .bgHome {
    .search {
      margin-top: 60px !important;
    }
  }

  .airlinLogo {
    width: 25% !important;
  }

  .login-div {
    width: 90% !important;

    .login-left {
      position: relative;

      .login-left-inner {
        width: 80% !important;
        min-height: auto !important;
        margin-bottom: 20px;

        img.closeImg {
          position: absolute;
          width: 15px;
          height: 15px;
          right: 15px;
          top: 15px;
          cursor: pointer;
        }

        h3 {
          margin-top: 30px !important;
        }
      }
    }

    .login-right {
      background: none !important;
      text-align: center;
      margin-bottom: 120px;
      min-height: 160px !important;
      border-top: 1px dashed #eee;

      h4 {
        margin-top: 20px !important;
        color: #000;
      }

      span {
        color: #000;
      }

      button {
        margin-top: 20px;
        bottom: 0;
        right: 35px;
        width: 80%;
      }
    }
  }

  .logged_region {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-family: "Roboto", Myanmar3;

    .logged_name {
      color: #0d87dd;
    }
  }

  .privacyPolicy {
    width: 100% !important;
    padding: 0px 20px;
  }

  .bgHome .homeContainer {
    height: auto !important;
    column-gap: initial !important;
  }

  .inApp .bgHome .homeContainer #gorgias-chat-icon,
  .homeContainer #gorgias-chat-icon {
    margin-right: 18px !important;
  }

  .bgHome .homeContainer #gorgias-chat-icon {
    margin-right: 0px !important;
  }

  .logo {
    height: 24px;
    margin-top: 9px !important;
  }

  /********* Gorgias CSS ***********************/
  .mobile_homeMenu {
    align-self: center;
    justify-content: flex-end;
    grid-column-gap: 18px !important;
    -webkit-column-gap: 18px !important;
    column-gap: 18px !important;
  }

  .logo_anchor {
    margin-right: auto;
    height: 50px;
    display: flex;
    align-items: center;
  }

  /* for non in-app */
  .non-in-app-gorgias#gorgias-chat-container iframe#chat-button {
    // bottom: auto;
    // top: 10px;
    // height: 30px !important;
    // width: 32px !important;
    // background: transparent;
    // margin-right: 85px;
    // position: absolute;
    opacity: 0;
  }

  .non-in-app-gorgias#gorgias-chat-container iframe#chat-campaigns {
    position: absolute;
    top: 42px;
    bottom: initial;
    width: 290px;
    margin-right: calc(50% - 145px);
  }

  /* for in-app */
  .in-app-gorgias#gorgias-chat-container iframe#chat-button {
    // bottom: auto;
    // top: 8px;
    // height: 30px !important;
    // width: 32px !important;
    // background: transparent;
    // margin-right: 85px;
    // position: absolute;
    opacity: 0;
  }

  /********* Gorgias CSS ***********************/

  /************ IN APP CSS *********************/
  .inWeb.bgKbz {
    .menuPhone {
      filter: brightness(0) invert(1);
    }
  }
  .inApp.bgkbz {
    .menuPhone {
      filter: brightness(0) invert(1);
    }
  }
  .inApp {
    @media (max-width: 380px) {
      .bgKbz {
        height: 95vh !important;
      }
    }

    @media (min-width: 381px) and (max-width: 780px) {
      .bgKbz {
        background-color: #0b55a8 !important;
        background-image: none !important;
        height: auto !important;
      }
    }

    #gorgias-chat-icon {
      margin-right: 18px !important;
    }

    .bgHome,
    .bgBus {
      width: 100% !important;
      max-width: 600px !important;
      min-width: 100% !important;
      background-image: none !important;
      //height: auto !important;
      padding-bottom: 30px !important;

      .headerMenu .header-menu-item span {
        color: #fff;
      }

      #gorgias-chat-icon svg,
      .normalPhoneWhite {
        filter: brightness(0) invert(1);
      }

      .headerMenu .header-menu-item.chosen-item {
        border-bottom: 2px solid #fff;
      }
    }

    .bgHome.bgMpitesan,
    .bgHotel#bgHotel.bgMpitesan,
    .bgHome.bgCitizenPay,
    .bgHotel#bgHotel.bgCitizenPay,
    .bgBus.bgCitizenPay,
    .bgBus#bgBus.bgCitizenPay,
    .bgBus.bgMpitesan,
    .bgBus#bgBus.bgMpitesan,
    .bgHome.bgAyaPay,
    .bgBus#bgBus.bgAyaPay,
    .bgHome.bgMomoney,
    .bgBus#bgBus.bgMomoney {
      // .headerMenu .header-menu-item span {
      //   color: #000 !important;
      // }

      #gorgias-chat-icon svg {
        filter: brightness(0) !important;
      }

      .headerMenu .header-menu-item.chosen-item {
        border-bottom: 2px solid #000;
      }
    }

    .bgHotel#bgHotel {
      // min-height: 140vh !important;
      min-height: 80vh !important;

      .headerMenu .header-menu-item span {
        color: #fff;
      }

      #gorgias-chat-icon svg,
      .menuPhone {
        filter: brightness(0) invert(1);
      }

      .headerMenu .header-menu-item.chosen-item {
        border-bottom: 2px solid #fff;
      }
    }

    // .bgWave,
    // .bgWave#bgHotel {
    //   background-color: #1692c5 !important;
    //   background-image: none !important;
    //   min-height: 660px;
    // }

    // //.bgKbz,
    // .bgKbz#bgHotel {
    //   background-color: #0b55a8 !important;
    //   background-image: none !important;
    // }

    // .bgOnePay,
    // .bgOnePay#bgHotel {
    //   background-color: #45acab !important;
    //   background-image: none !important;
    //   min-height: 660px;
    // }

    .bgMpitesan {
      background-color: #fff !important;
      background-image: none !important;
    }

    .bgAyaPay {
      background-color: #fff !important;
      background-image: none !important;
    }

    .btnBlueSearch .btnWave,
    #bgHotel .btnBlueSearch .btnWave {
      // background-color: #fee337 !important;
      color: #000 !important;
    }

    // .btnBlueSearch .btnKbz,
    // #bgHotel .btnBlueSearch .btnKbz {
    //   background-color: rgba(0, 0, 0, 0.5) !important;
    //   color: #fff !important;
    // }

    // .btnBlueSearch .btnOnePay,
    // #bgHotel .btnBlueSearch .btnOnePay {
    //   background-color: #2e9d68 !important;
    //   color: #fff !important;
    // }

    // .btnBlueSearch .btnMpitesan,
    // #bgHotel .btnBlueSearch .btnMpitesan {
    //   background-color: #ec3833 !important;
    //   color: #fff !important;
    // }

    // .btnBlueSearch .btnAyaPay,
    // #bgHotel .btnBlueSearch .btnAyaPay {
    //   background-color: #cd1f2c !important;
    //   color: #fff !important;
    // }

    .btnBlueSearch .btnCitizenPay,
    #bgHotel .btnBlueSearch .btnCitizenPay {
      // background-color: #ec008c !important;
      color: #fff !important;

      span {
        font-weight: 500;
      }
    }
  }

  /************ IN APP CSS *********************/

  .flight-info {
    padding: 0 15px 10px 15px;

    .home-info-card-2 {
      margin-top: 10px;
    }
  }

  .home-info-card-2 {
    border-radius: 20px !important;
    padding: 15px;
    display: flex;

    flex-direction: row;
    margin-top: 20px;
    overflow: visible !important;

    img {
      align-self: flex-start;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      span:not(:first-child) {
        margin-top: 8px;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      line-height: 20px;
    }

    span {
      font-size: 12px;
      line-height: 20px;
      color: #898989;
    }

    .info {
      font-size: 14px !important;
      color: #000000 !important;
    }

    a {
      color: #000000 !important;
      text-decoration: underline;
      text-underline-offset: 0.1em;
      font-weight: 600;
    }

    span:last-child {
      color: #0d87dd;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .bgCar .search {
    margin-top: 0px;
  }

  .bgHome .homeContainer,
  .bgCar .homeContainer,
  .bgHotel .homeContainer,
  .bgBus .homeContainer {
    flex-direction: column !important;
  }

  .homeContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
    height: auto;

    .login_btn {
      img {
        @media (max-width: 799px) {
          width: 25px;
        }
      }
    }
    .language-select {
      padding: 0;
      img {
        margin: 0 !important;
      }
    }
    .wrap {
      @media (max-width: 425px) {
        gap: 10px !important;
      }
    }
  }

  #gorgias-chat-icon {
    svg {
      fill: black !important;
    }
  }

  .mobile_homeMenu {
    #gorgias-chat-icon,
    .header_phone_region,
    .login_btn {
      position: static;
      right: 0;
      top: 0;
      display: flex;
      margin-right: 0;
      margin-left: 0;
      width: 26px;
      height: 30px;
      align-items: center;
      justify-content: center;
      text-align: center;

      svg {
        fill: black !important;
      }
    }

    .menuPhone {
      width: 20px;
    }

    .mobile_login_icon {
      width: 25px;
    }

    .not_inapp_login {
      width: 30px;
      .not_inapp_icon {
        width: 30px;
      }
    }
  }

  .covidInfo {
    z-index: 1;
  }

  .web-from-to {
    display: none !important;
  }

  .dateRange {
    display: none;
  }

  body {
    background-color: #f4f3f4;
  }

  .loadingWrapper {
    width: 110% !important;
  }

  .bgHome {
    background-image: none !important;
    height: auto !important;
    //fbackground-color: rgb(247, 249, 254) !important;

    .myanmarCitizen {
      border-radius: 10px !important;
      .MuiFormControlLabel-label {
        margin-left: 5px;
      }
      .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        margin-left: 15px;
      }
      .css-9l3uo3 {
        margin-left: 15px;
      }
      .MuiFormGroup-row {
        border-radius: 10px !important;
      }
    }
  }
  .bgViber .MuiFormGroup-row {
    background-color: #e5e1ff !important;
    span {
      color: black !important;
    }
  }

  .clickAwayDiv {
    float: none;
    display: inline;
  }

  .homeContainer {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    // padding: 0px 20px !important;
    padding: 0;

    .DateInput_input {
      font-size: 18px !important;
      text-align: right;
      float: right;
    }

    .DateInput_input__focused {
      width: 150px;
      margin-left: 150px;
      margin-top: -40px !important;
      position: absolute;
    }
  }

  .mobile-from-to {
    margin-top: 20px;
    display: block !important;

    label {
      float: left;
      width: 19%;
      font-size: 18px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }

    .MuiInputBase-input {
      width: 100%;
      float: right;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 700;
      color: #231f20;
      font-size: 18px;
    }

    .MuiFormControl-root {
      width: 80%;
    }
  }

  #homeFlag .flag-select {
    margin-top: 14px !important;
  }

  .myanmar {
    font-size: 53px !important;
    line-height: 30px !important;
  }

  .bgHome .logo {
    height: 24px;
    padding-top: 0px;
  }

  .bgHome .logo.kbzLogo {
    width: 100% !important;
  }
  .mobile_homeMenu .logo.CRLogo {
    width: 50px !important;
  }

  .menu {
    min-height: 70px !important;
    padding-left: 20px !important;
    padding-right: 0px !important;

    span {
      font-size: 20px !important;
      float: right;
      margin-left: 20px;
    }

    #hotel-new-menu-mobile-icon {
      font-size: 16px !important;
    }
  }

  .inWeb #fl-home-btn-search.btnKbz.btnActive,
  .inWeb #fl-home-btn-search.btnKbz.btnDisable,
  #bgHotel.inWeb .btnBlueSearch .btnKbz {
    background-color: #052a54 !important;
    color: #fff !important;
  }

  .inWeb #fl-home-btn-search.btnViber.btnActive,
  .inWeb #fl-home-btn-search.btnViber.btnDisable,
  #bgHotel.inWeb .btnBlueSearch .btnViber {
    background-color: #794aff !important;
    color: #ffffff !important;
  }

  .homeCenterInfo {
    font-family: "Poppins", Myanmar3 !important;
    font-weight: 500;
    font-size: 15px !important;
    height: 15px !important;
    margin-top: calc(10vh - 120px) !important;

    .divDiscover {
      width: 268px !important;
    }
  }

  .search.inAppSearch {
    // height: 300px !important;
    height: 240px !important;
  }

  .search.flightSearch {
    display: flex !important;
  }

  .search {
    height: 295px;
    min-height: 295px;
    max-height: 295px;
    padding: 0px !important;
    margin-top: 90px !important;
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
      0 3px 7px -3px rgba(0, 0, 0, 0.3);

    .swapDestination {
      border: 1px solid #e6e7e8;
      background: #fff;
      border-radius: 50px;
      padding: 6px;
      margin: 0px;
      left: 20px;
      z-index: 1;
      position: absolute;
      top: -30px;
    }
  }

  .bgHome .departfrom .passenger {
    line-height: 13px;
    height: 50px !important;
  }

  .bgBus .departfrom .passenger {
    line-height: 40px;
  }

  .bgHome #SearchBox {
    min-height: 320px !important;
  }

  #MobilePassengerType .passengerConfirm.MuiGrid-item {
    width: unset !important;
    margin: 15px;
    min-height: 12vh !important;

    button {
      width: 100%;
      background-color: #0d87dd !important;
      padding: 14px 22px;
      border-radius: 4px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;

      span {
        font-family: "Roboto", Myanmar3 !important;
        color: #fff;
        font-weight: 500;
        font-size: 17px !important;
        text-transform: capitalize;
      }
    }
  }

  @media (max-width: 320px) and (max-height: 480px) {
    #MobilePassengerType .MobilePassengerTypeLable {
      margin-top: 10px !important;
    }
  }

  @media (max-width: 760px) {
    // .myanmarCitizen {
    //   border-right: 1px solid #5a5a5a !important ;
    //   //background-color: #5a5a5a !important;
    // }
    .bgViber .myanmarCitizen {
      background-color: transparent !important;
      border-right-color: white !important;
    }
    #bgHotel.bgKbz,
    .bgBus.bgKbz,
    .bgBus.bgKbz {
      background-color: #0b55a8 !important;
      min-height: 640px !important;
    }

    .bgHome.bgKbz {
      background-color: #0b55a8 !important;
      min-height: 660px !important;
    }

    .bgBus .bgHomeService {
      margin-top: 50px;
    }

    .bgCar.bgKbz {
      background-color: #0b55a8 !important;
      min-height: 530px !important;
    }

    .inWeb.bgHome.bgKbz {
      height: auto !important;
    }
    .inWeb.bgCityReward #hl-home-btn-search,
    .inWeb.bgCityReward #bs-home-btn-search {
      background-color: #6e51a2 !important;
    }
  }

  /************* Start Hotel Mobile Home Page ************/
  #bgHotel {
    background-position: 0 0;
    //background-color: #f7f9fe !important;
    background-image: none !important;

    .homeContainer {
      height: auto !important;
    }

    .myanmarCitizen {
      border-radius: 10px !important;

      .MuiFormGroup-row {
        border-radius: 10px !important;
      }
    }

    .hotelHomeCenterInfo.homeCenterInfo {
      margin-top: -20px !important;
      margin-bottom: 19vh !important;
    }

    .mobileHotelDestination.departfrom {
      display: inline-block;
      margin-top: -10px !important;

      > label {
        width: 50% !important;
        font-size: 13px !important;
      }

      > .MuiTextField-root {
        margin-top: 4px !important;

        > div {
          margin-top: 0 !important;
        }
      }

      #hotelCityInput {
        font-size: 17px !important;
        text-overflow: ellipsis;
      }
    }

    .departfrom {
      height: 56px !important;
    }

    .hotelDepartFrom {
      height: 22px !important;
    }

    .roundTripPassengerType {
      //margin-top: -20px !important;
    }

    .fieldLbl {
      color: #a5a7a6 !important;
      line-height: 22px !important;
      font-size: 13px;
    }

    .mdatepicker .SingleDatePickerInput_calendarIcon,
    .mdatepicker .DateInput {
      width: 100% !important;
    }

    .homeContainer .flightSearch .DateInput_input {
      text-align: left !important;
      width: 100% !important;
      font-size: 17px !important;
      font-weight: 600;
      line-height: 20px;
    }

    .mdatepicker .SingleDatePicker .SingleDatePickerInput {
      margin-top: -15px;
    }

    .passenger label {
      width: 100% !important;
    }

    .departfrom .MuiInputBase-input {
      text-align: left;
    }

    .search {
      min-height: 200px !important;
      margin-top: 60px !important;
      box-shadow: none !important;
      border: 1px solid #a5a7a6;
      border-radius: 10px;
      height: 219px;
    }

    .hotel-text {
      transform: translate(0, -4em) !important;
    }

    .hotelListPopup.MuiPaper-root {
      left: 15px !important;
      width: calc(100% - 31px) !important;
    }

    .btnBlueSearch {
      //margin-top: 6.3vh !important;
      //flex-basis: 5% !important;
      top: 0 !important;
      margin-top: 40px !important;
      width: 100% !important;

      button {
        box-shadow: none !important;
        border-radius: 10px;
      }
    }

    .home-info-card-2 {
      margin-top: 20px;
    }
  }

  /************* End Hotel Mobile Home Page ************/

  .hasReturn,
  .inAppSearch {
    .swapDestination {
      top: -18px !important;
    }
  }

  .menuList {
    display: none;
  }

  .container {
    max-width: 100% !important;
  }

  .oneWayTripTypeButton.activeTripTypeButton {
    box-shadow: 2px 1px 4px -1px rgba(0, 0, 0, 0.1);
  }

  .roundTripTypeButton.activeTripTypeButton {
    box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .myanmarCitizen {
    width: 100%;
    position: relative !important;
    margin-top: -90px;
    height: 45px;
    padding: 0px !important;
    margin-left: 0px !important;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    //   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    // background-color: #ebecf0 !important;
    border-radius: 10px !important;

    span {
      font-weight: normal !important;
    }

    .MuiFormControlLabel-root {
      margin: auto;
      padding-right: 5px;
      @media (max-width: 425px) {
        margin-right: 0 !important;
        padding-right: 0;
      }
    }

    .MuiFormControlLabel-label.Mui-disabled {
      color: #000 !important;
    }

    label {
      span {
        width: 25% !important;
        margin: 0px;
      }
    }

    .MuiGrid-root {
      padding: 0px 20px;
    }

    .MuiFormGroup-row {
      height: 49px;
      // background-color: #ebecf0 !important;
      background-color: #ebecf0;
      border-radius: 10px !important;
    }

    span {
      font-size: 16px !important;
      width: 45%;
    }
  }

  .myanmarCitizen.inApp,
  .busSearhRadio .myanmarCitizen {
    margin-top: -60px !important;
    border-right: none !important;
    background-color: transparent !important;
  }

  .returnFlightRadio {
    width: 100%;
    position: relative !important;
    margin-top: -45px;
    height: 45px;
    padding: 0px !important;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: 0px !important;

    .MuiRadio-colorSecondary .MuiSvgIcon-root {
      font-size: 1rem !important;
      color: #fff !important;
    }

    .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root {
      font-size: 1rem !important;
      color: #01b7f2 !important;
    }

    .MuiFormControlLabel-root {
      margin: auto;
      padding-right: 5px;
      margin-right: 5px !important;
    }

    label {
      span {
        width: 25% !important;
        margin: 0px;
      }
    }

    .MuiGrid-root {
      padding: 0px 20px;
    }

    .MuiFormGroup-row {
      height: 45px;
    }

    span {
      font-size: 16px !important;
      width: 45%;
      color: #fff;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      margin: auto;
    }
  }

  .searchIcon {
    display: none !important;
  }

  button#fl-home-btn-search {
    margin-bottom: 10px;
  }

  .btnBlueSearch {
    display: block !important;
    position: relative;
    //flex-basis: 5% !important;
    top: 0 !important;
    width: 100%;
    margin-top: 40px !important;

    button {
      width: 100%;
      margin-top: -20px !important;
      text-transform: capitalize !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 500;
      font-size: 17px !important;
      height: 50px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    }
  }

  .btnSearch {
    display: none !important;
  }

  #fl-home-btn-search span,
  #ia-fl-home-btn-search span {
    pointer-events: none;
  }

  .menu {
    float: none !important;
    text-transform: uppercase;
  }

  .showMenu {
    width: 300px;

    .MuiDrawer-paper {
      background-color: #000;
      opacity: 0.6;
      width: 290px;
      height: auto;
      top: 80px !important;
    }
  }

  .MuiBackdrop-root {
    top: 80px !important;
  }

  .homeMenu {
    float: left;
    top: 15px !important;
    padding: 0px !important;
    min-width: 12px !important;
    float: left;
    margin-top: 10px;
    opacity: 1 !important;
  }

  .checkReturnDate,
  .calendarIcon {
    opacity: 0 !important;
  }

  #fl-home-btn-menuIcon .MuiSvgIcon-root,
  #hl-home-btn-menuIcon .MuiSvgIcon-root {
    color: #2f80ed !important;
  }

  #hl-home-btn-menuIcon {
    position: absolute;
    left: 15px;

    .MuiTouchRipple-root {
      background-color: transparent !important;
    }
  }

  .showMenu.hotelShowMenu {
    .MuiDrawer-paper {
      top: 50px !important;
    }

    .MuiBackdrop-root {
      top: 50px !important;
    }
  }

  .menuIcon {
    float: left;
    min-width: 30px !important;
    // margin-top: 17px !important;
    width: 48px;
    height: 48px;
    margin-left: -12px !important;
    margin-top: 5px !important;

    // z-index: 1500;
    .MuiButton-root {
      min-width: 30px !important;
    }

    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }

  .chooseReturnDate {
    opacity: 1 !important;
    margin-left: 0px !important;
  }

  .departfrom {
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0px !important;
    padding: 0px 20px !important;
    min-width: 100% !important;
    position: relative;
  }

  .departfrom {
    .MuiInputBase-input {
      text-align: right;
    }
  }

  .MuiFormControl-root label {
    width: 50%;
    margin-top: -25px;
  }

  .MuiInputLabel-shrink {
    transform: none !important;
    line-height: 70px !important;
  }

  .datepicker {
    padding: 0px 20px !important;
  }

  .bgHome .DateInput_input__readOnly.DateInput_input__focused_3 {
    display: none;
  }

  .returnFlight {
    margin-top: 30px !important;
    float: none !important;
    margin-left: 0px !important;
  }

  .departFlight {
    margin-top: -8px !important;
  }

  .airportList {
    padding: 10px 15px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: #00aeef;
    line-height: 20px;
  }

  .departfrom {
    .MuiTextField-root {
      width: 100%;
    }

    .MuiInputBase-root {
      margin-top: 0px !important;
    }
  }

  .divpackages {
    margin-top: -30px;
  }

  .popupSearch {
    height: 95px;
    background-color: #00aeef;
    position: sticky;
    top: 0;
    z-index: 3;

    .searchTitle {
      color: #ffffff;
      font-family: "Roboto", Myanmar3;
      font-weight: 500;
      line-height: 40px;
      padding-top: 2px;
      margin-left: 40%;
      font-size: 16px;
    }

    .MuiTextField-root {
      width: 93%;
      margin-top: -5px !important;
    }

    .MuiInputBase-input {
      text-align: left !important;
      padding: 10px 15px !important;
      border-radius: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .MuiInputBase-input::placeholder {
      font-family: "Roboto", Myanmar3;
      font-weight: 400;
      font-size: 14px;
    }

    .MuiInputBase-root {
      left: 13px !important;
      margin-top: 10px !important;
    }

    .MuiButton-text {
      margin-left: -6px;
    }

    input {
      float: left;
      background-color: #fff;
    }

    .MuiInputAdornment-positionEnd {
      margin-left: -30px;
    }
  }

  .topCity {
    height: 50px;
    color: #000000;
    font-family: "Roboto", Myanmar3;
    font-weight: 500;
    background-color: #e4e4e4;
    padding: 10px;
    margin-bottom: 15px !important;
    line-height: 30px;
  }

  .passenger {
    padding-top: 4px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    border-radius: 5px;

    .MuiInputAdornment-root {
      display: none;
    }

    label {
      float: left;
      width: 15%;
      font-size: 18px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }
  }

  .flightPassenger {
    padding-top: 12px !important;
  }

  .slideContainter {
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 1501;
    max-height: 100%;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-radius: 0px !important;
  }

  .closeAiport {
    float: left;
    margin-top: 5px !important;

    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }

  .airportSearchIcon {
    color: #2699fb !important;
  }

  .headerLogo {
    display: none !important;
  }

  .mdatepicker .SingleDatePicker {
    width: 100% !important;
    text-align: left;
  }

  .fieldLbl {
    float: left;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
  }

  .mdatepickerReturn .SingleDatePicker {
    text-align: left;
    margin-left: 3px;
    width: 80%;

    .DateInput_input {
      margin-left: 22px;
    }
  }

  .SingleDatePickerInput_calendarIcon {
    margin: 0px !important;
    padding: 0px !important;
    width: 45%;
  }

  .mdatepickerReturn {
    margin-left: 3px !important;
  }

  .mdatepickerReturn .DateInput {
    width: 72% !important;
  }

  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 26%;
  }

  // .mdatepicker .SingleDatePickerInput_calendarIcon {
  //   width: 47%;
  // }

  .mdatepicker .SingleDatePickerInput__withBorder {
    width: 100% !important;
  }

  .mdatepicker label,
  .mdatepickerReturn label {
    width: 13% !important;
  }

  .mdatepicker {
    .DateInput {
      width: 55% !important;
      line-height: 40px !important;
      float: none !important;
      font-weight: 700;
      overflow: hidden;
    }
  }

  .mdatepickerReturn {
    padding-right: 3px !important;

    .DateInput {
      width: 75%;
      line-height: 50px !important;
      float: none !important;
    }
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: #fff !important;
  }

  .bgViber .MuiFormControlLabel-label {
    color: black !important;
  }
  #bgHotel .mdatepicker,
  .mdatepickerReturn {
    .DateInput_input {
      padding-left: 0px !important;
    }
  }
  .mdatepicker,
  .mdatepickerReturn {
    height: 59px;
    margin: 0px !important;
    border-bottom: 1px solid #ddd;
    padding: 0px !important;

    .DateInput_input__focused {
      width: 0% !important;
    }

    .SingleDatePickerInput__withBorder {
      border: 0px !important;
      line-height: 29px;
      max-height: 59px;
    }

    .DateInput_input {
      padding: 5px 0px !important;
      font-weight: 700;
      font-size: 16px !important;
      padding-left: 42px !important;
    }
    #bgHotel .DateInput_input {
      padding-left: 0px !important;
    }

    label {
      float: left;
      height: 60px;
      width: 25%;
      font-size: 16px !important;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      color: #a5a7a6 !important;
    }

    .DateInput__disabled,
    .SingleDatePickerInput__disabled {
      background: #fff !important;
    }

    .DateInput__disabled {
      width: 70% !important;
    }
  }

  .editForm {
    width: 100%;
  }

  .divSearch .MuiInputBase-input {
    width: 100% !important;
  }

  .searchBox {
    .MuiInputBase-input {
      width: 100% !important;
    }
  }

  //FOOTER
  .divAirlinePartner {
    .bgWhite {
      flex-wrap: wrap;
      padding: 0 5px;
    }
  }

  .featuredInContainer {
    padding: 0 10px;
  }

  .divFeaturedIn {
    .col {
      width: 25%;
      padding: unset;

      .feature-img {
        width: 100% !important;
      }
    }
  }

  .grid-footer:last-child .titleSlider {
    text-align: left;
  }

  //Relief Flight
  .covidAlertBox {
    flex-wrap: wrap;
  }

  .confirmationBox.covidAlertBox {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .covidAlertBox img.moreInformation {
    top: -65px !important;
  }

  .confirmationBox.promotionBox {
    //left: 4vw !important;
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .hotel-back-btn .hotelDuration {
    font-size: 12px;
    color: #676767;
  }

  .mobile_homeMenu {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: flex-end;
    padding-top: 0px;
    width: 100%;
    max-height: 50px;
    grid-column-gap: 18px !important;
    -webkit-column-gap: 18px !important;
    column-gap: 18px !important;

    .menuIcon-back .MuiSvgIcon-root {
      fill: #000000;
    }

    .hotel.menuIcon-back .MuiSvgIcon-root {
      fill: #ffffff;
    }

    .menuIcon-back .backText {
      font-weight: 500;
      font-size: 16px;
      margin-left: 5px;
      color: #000000;
    }

    .hotel.menuIcon-back .backText {
      color: #ffffff;
    }
  }

  .divAirlinePartner {
    .bgWhite {
      div {
        width: 100%;
      }
    }
  }

  .confirmationBox.covidAlertBox {
    box-sizing: border-box;
    margin: 3px;
    width: 95% !important;
  }
}

@media (max-width: 599px) {
  .offlintTag {
    margin-top: -10px !important;
    margin-left: -10px !important;
  }

  .grid-footer {
    margin-bottom: 35px !important;
  }

  .grid-footer:nth-child(3) {
    .footer-bottom {
      margin: 0 auto;
      @media (max-width: 599px) {
        width: 80% !important;
      }
    }

    .titleSlider {
      text-align: center;
    }
  }

  .grid-footer:first-child,
  .grid-footer:nth-child(2) {
    display: flex;
    justify-content: center;
    height: auto;
  }

  .grid-footer:nth-child(4) {
    text-align: center;

    div > div {
      justify-content: center;

      div:last-child span {
        text-align: left;
      }
    }
  }

  .grid-footer:last-child {
    justify-content: center;

    .titleSlider {
      text-align: center;
    }
  }

  .grid-footer.flymyaFooterLogo {
    text-align: start;
  }

  .flymyaFooterLogo {
    span {
      margin-bottom: 15px;
    }

    img {
      max-width: 200px;
      text-align: start;
    }
  }

  .sortingBox .MuiInput-root.mm-pull-right {
    width: 170px !important;
  }
}

@media (min-width: 400px) {
  .mdatepickerReturn label {
    width: 13% !important;
  }

  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 62px !important;
  }

  .mdatepickerReturn .SingleDatePicker .DateInput_input {
    width: 73% !important;
  }

  .mdatepickerReturn .DateInput {
    width: 75% !important;
  }

  .MuiContainer-root {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}

@media (max-width: 420px) {
  .MuiContainer-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media (max-width: 370px) {
  .departfrom {
    padding: 0px 15px !important;
  }

  .infoSection br {
    display: none;
  }

  .infoSection .departTime {
    // margin-top: 45px !important;
  }

  .btnEditSearch span {
    font-size: 16px !important;
  }

  .sortingBox .MuiInput-root {
    .MuiSelect-icon {
      right: -13px !important;
    }
  }

  .sortingBox .MuiInput-root.en-pull-right {
    width: 130px !important;
  }

  .sortingBox .MuiInput-root.mm-pull-right {
    width: 156px !important;
  }

  .myanmarCitizen span {
    padding: 2px !important;
    width: 44% !important;
  }

  .infoSection .airlnelbl {
    position: relative !important;
  }

  .mobile-from-to label {
    width: 22% !important;
  }

  .mdatepicker .SingleDatePicker {
    width: 100% !important;
  }

  .mdatepicker .SingleDatePickerInput_calendarIcon {
    width: 41% !important;

    .fieldLbl {
      width: 105px !important;
      text-align: left;
    }
  }

  .mdatepicker .DateInput {
    width: 59% !important;
  }

  .mdatepickerReturn .DateInput {
    width: 68% !important;
  }

  .mdatepickerReturn .SingleDatePickerInput_calendarIcon {
    width: 32% !important;
  }

  .mdatepickerReturn .SingleDatePicker {
    width: 80% !important;
  }

  .mdatepickerReturn label {
    width: 12% !important;
    margin-left: -3px !important;
  }

  .infoLbl {
    margin-left: -15px;
  }

  .borderInfo {
    width: 75px !important;
  }

  .flag-select .selected--flag--option {
    padding: 0px !important;
  }

  .bgHome .homeContainer .mobile-from-to .MuiFormControl-root {
    width: 78% !important;
  }

  //FOOTER
  .homeContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .airlinePartner,
  .airlineFeatured {
    font-size: 21px !important;
  }

  .airlineImg {
    width: 92px;
    height: 30.5px;
    padding: 0 5px;
  }

  .divFeaturedIn {
    padding-top: 30px !important;
  }

  .featuredInContainer {
    .col {
      div {
        width: 100% !important;
      }
    }
  }

  .footer {
    .container .MuiGrid-container {
      padding: 0 20px;
      margin-bottom: 15px;
    }
  }

  .footer-bar {
    flex-direction: column;
    align-items: unset;

    .MuiFormControl-marginNormal {
      margin-bottom: 15px !important;
    }
  }

  .footer-textfield input {
    width: 279px;
    margin-right: 0;
  }

  .footer-subscribe {
    align-self: flex-end;
  }

  .grid-footer {
    margin-bottom: 15px;
  }
}

.flag-select {
  float: right !important;
}

.flag-select .selected--flag--option {
  font-size: 16px !important;
  color: #fff !important;
}

.flag-select .arrow-down {
  color: #fff !important;
}

/*feature image sprite*/
@media (max-width: 768px) {
  .feature-img-ncra {
    background-position: -273px -53px;
    background-size: 685px !important;
    width: 100px !important;
  }

  .feature-img-travel-leisure {
    background-position: 11px -112px !important;
    background-size: 745px !important;
    width: 90px !important;
  }

  .feature-img-irrawaddy {
    background-position: -342px -8px !important;
    background-size: 778px !important;
  }

  .feature-img-yangon-life {
    background-position: -206px -119px !important;
    background-size: 787px !important;
    max-width: 100px;
  }

  .feature-img-irrawady-burma {
    background-position: -1px -10px !important;
    background-size: 922px !important;
  }

  .feature-img-mizzima-burma {
    background-position: 0px -84px !important;
    background-size: 915px !important;
  }

  .feature-img-malay-mail-online {
    background-position: -465px -14px !important;
    background-size: 800px !important;
    max-width: 100px !important;
  }

  .feature-img-sd-asia {
    background-position: -563px -93px !important;
    background-size: 978px !important;
  }

  .feature-img-mizzima-eng {
    background-position: -628px -4px !important;
    background-size: 750px !important;
    width: 100px !important;
  }

  .feature-img-mingalapar {
    background-position: -576px -12px !important;
    background-size: 825px !important;
    width: 100px !important;
  }

  .feature-img-tech-in-asia {
    background-position: -470px -59px !important;
    background-size: 700px !important;
    width: 100px !important;
  }

  /*feature image sprite*/

  /*airline partner image sprite*/
  .divAirlinePartner {
    padding-top: 0px !important;

    .bgWhite {
      // div {
      //   //width: 184px !important;
      //   width: 175px !important;

      div {
        // width: 30%;
        // width: 100%;
      }

      // }
      .icons {
        div {
          // width: 300px !important;
        }

        .airline-yangon-airway {
          background-position: 300px 6px;
          background-size: 300px;
          width: 180px !important;
          height: 70px;
          margin-left: 20px;
        }

        .airline-mna {
          background-position: -203px 74px;
          background-size: 416px;
          width: 184px;
          height: 75px;
          display: none;
        }

        .airline-mna-mobile {
          display: block;
          width: 180px;
        }

        .airline-air-thanlwin-mobile {
          display: block;
          width: 180px;
        }

        .airline-myp {
          background-position: 0px -79px;
          background-size: 400px;
          width: 184px;
          height: 75px;
          margin-top: -22px;
          margin-left: 14px;
        }

        .airline-air-thanlwin {
          background-position: -224px -68px;
          background-size: 400px;
          //width: 184px;
          width: 176px;
          height: 75px;
          margin-top: -24px;
          margin-left: 14px;
        }

        .airline-airkbz {
          background-position: 178px 1px;
          background-size: 353px;
          // width: 192px;
          width: 179px;
          height: 75px;
          margin-right: 17px;
        }

        .airline-gma {
          background-position: 1px -85px;
          background-size: 391px;
          width: 184px;
          height: 75px;
          height: 75px;
          display: none;
        }
      }
    }
  }

  /*airline partner image sprite*/
  .allday-img {
    background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4G7sU5i6rTKBralqLeCZAr/6f3637e8be7652b1a57b94218e0a9e7c/icon-sprite-min.png");
    background-position: -6px -82px;
    background-size: 187px;
    width: 58px;
    height: 49px;
    float: right;
    margin-right: 5px !important;
  }
}

.returnMobileText {
  margin-left: -31px !important;
}

.tripTypeDefaultText {
  padding-top: 0 !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  border-radius: 5px;
}

@media (max-width: 375px) {
  .search {
    margin-bottom: 3px !important;
  }

  .returnText {
    font-size: 18px !important;
  }

  .stayText {
    font-size: 14px !important;
  }

  .confirmationBox button span {
    font-size: 12px !important;
  }
}

.mobileTripType div {
  padding: 15px 10px !important;
}

@media (max-width: 780px) {
  #DiscoverMyanmarContainer {
    display: none !important;
  }

  .bgHome #areaCode {
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25),
      0 1px 3px -1px rgba(0, 0, 0, 0.3);
  }

  .privacyPolicyHeader {
    display: block !important;
    visibility: unset !important;

    .headerLogo {
      display: inline-block !important;
      padding-top: 15px;
    }
  }
}

@media (max-width: 959px) {
  #MobilePassengerType {
    outline: none !important;
  }

  #MobilePassengerType .mobile_passenger_type {
    top: 0 !important;
    position: relative !important;
  }

  #MobilePassengerType .MuiPaper-elevation1 {
    width: 100% !important;
    text-align: center !important;
  }

  #MobilePassengerType .MuiGrid-item {
    width: 100% !important;
    border-bottom: 1px solid #e4e4e4 !important;
    min-height: 16vh !important;
    margin-bottom: 20px !important;
  }

  #MobilePassengerType .MuiGrid-item:nth-child(3) {
    border-bottom: none !important;
  }

  #MobilePassengerType .MuiGrid-item:last-child {
    border-bottom: none !important;
  }

  #MobilePassengerType .ageSelect {
    margin-top: 10px;
    width: 100% !important;
    float: none !important;
    margin-top: 15px;
  }

  #MobilePassengerType .chooseAge {
    width: 100% !important;
    float: none !important;
  }

  .react-numeric-input b {
    background: transparent !important;
  }

  #MobilePassengerType .react-numeric-input {
    width: 35% !important;
    font-size: 30px !important;
    margin-top: 10px !important;
  }

  #MobilePassengerType .react-numeric-input i {
    background-color: rgb(0, 174, 239) !important;
  }

  #MobilePassengerType .react-numeric-input b:nth-child(2) i {
    background-color: rgb(0, 174, 239) !important;
  }

  #MobilePassengerType .numeric-counter {
    border: none !important;
    width: 100% !important;
    outline: none !important;
    pointer-events: none !important;
  }

  #AMobilePassengerType .react-numeric-input b {
    border: 1px solid rgb(0, 174, 239) !important;
    border-radius: 50% !important;
    width: 26px !important;
  }

  #MobilePassengerType .ageType {
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  .MobilePassengerTypeTitle {
    width: 100% !important;
    background: #000 !important;
    outline: none !important;
    margin-top: 5px !important;
  }

  .MobilePassengerTypeTitle span {
    margin-left: 10px !important;
    color: #fff !important;
    line-height: 43px !important;
    width: 100% !important;
    display: inline-block;
  }
}

.MobilePassengerTypeLable {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 !important;
  margin-top: 52px !important;
}

.MobilePassengerTypeLable span {
  width: 20% !important;
  margin: 0 auto !important;
  padding: 0 !important;
  text-align: center !important;
  color: #fff !important;
  margin-top: -5px !important;
}

#MobilePassengerType .closeAiport {
  margin-top: -2px !important;
}

/* new UI for round trip type */
@media (max-width: 780px) {
  .mmBusLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mmBusLogo img {
    width: 70px !important;
    //float: left !important;
  }

  .mmBusLogo span {
    color: black;
    line-height: 70px;
    margin-top: 10px;
    //float: left !important;
  }

  .inAppPoweredByText {
    color: white !important;
  }

  .tripTypeContainer {
    width: 100% !important;
    height: 45px !important;
    background: #f0f0f0 !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .tripTypeButton {
    width: 50% !important;
    float: left !important;
    font-family: "Roboto Regular" !important;
    font-size: 16px !important;
    line-height: 42px !important;
    text-align: center !important;
    border-color: 2px solid transparent;
    color: #231f20 !important;
    opacity: 0.2 !important;
    cursor: pointer !important;
  }

  .tripTypeButton span {
    width: 100% !important;
    display: inline-block !important;
  }

  .oneWayTripTypeButton {
    border-top-left-radius: 4px !important;
  }

  .activeTripTypeButton {
    background: #ffffff !important;
    font-family: "Roboto Regular" !important;
    color: #00aeef !important;
    border-bottom: 2px solid #00aeef !important;
    height: 43px !important;
    opacity: 1 !important;
  }

  .activeTripTypeBtnWebMobile {
    color: #00aeef !important;
    border-bottom: 2px solid #00aeef !important;
  }

  .activeTripTypeBtnKBZ {
    color: #052a54 !important;
    border-bottom: 2px solid #052a54 !important;
  }

  .activeTripTypeBtnMpitesan {
    color: #ec3833 !important;
    border-bottom: 2px solid #ec3833 !important;
  }

  .activeTripTypeBtnCitizenspay {
    color: #ec008c !important;
    border-bottom: 2px solid #ec008c !important;
  }

  .activeTripTypeBtnWavemoney {
    color: #4ab0fe !important;
    border-bottom: 2px solid #4ab0fe !important;
  }

  .activeTripTypeBtnOnepay {
    color: #08a98e !important;
    border-bottom: 2px solid #08a98e !important;
  }

  .activeTripTypeBtnAYApay {
    color: #a02225 !important;
    border-bottom: 2px solid #a02225 !important;
  }
  .activeTripTypeBtnViber {
    color: #794aff !important;
    border-bottom: 2px solid #794aff !important;
  }
  .activeTripTypeBtnMoMoney {
    color: #4db749 !important;
    border-bottom: 2px solid #4db749 !important;
  }

  .activeTripTypeBtnCityReward {
    color: #6e51a2 !important;
    border-bottom: 2px solid #6e51a2 !important;
  }
  .activeTripTypeButton span {
    font-weight: 700 !important;
  }

  .nonActiveTripTypeButton {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  .myanmarCitizen {
    margin-top: -60px !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .roundTripTypeButton {
    border-top-right-radius: 4px !important;
  }

  .departfrom {
    height: 45px !important;
    // height: 55px !important;
    // margin-top: -15px !important;
  }

  .mdatepicker .SingleDatePicker .SingleDatePickerInput {
    line-height: 30px !important;
  }

  .mobile-from-to {
    margin-top: 10px !important;
  }

  .fieldLbl {
    line-height: 30px !important;
  }

  .mdatepicker,
  .mdatepickerReturn {
    border-bottom: none !important;
  }

  .passenger {
    // padding-top: 7px !important;
    font-size: 16px !important;
  }

  .bgHome .swapDestination {
    left: 25% !important;
    top: -23px !important;
  }

  .swapDestination.MuiSvgIcon-root {
    font-size: 1.2rem !important;
    transform: rotate(90deg) !important;
  }

  .mobile-from-to label,
  .passenger label,
  .bgHome .fieldLbl {
    font-size: 14px !important;
  }

  .mobile-from-to .MuiInputBase-input,
  .homeContainer .flightSearch .DateInput_input {
    font-size: 15px !important;
    letter-spacing: 0.5px;
  }

  .roundTripPassengerType {
    // margin-bottom: -20px !important;
  }

  .nationality-radio-btn {
    width: 25% !important;
  }

  .myanmarCitizen label span {
    width: 4% !important;
  }

  .myanmarCitizen .MuiFormControlLabel-root {
    padding-right: 0 !important;
  }

  .infoSection span {
    display: block !important;
  }

  .chooseFlightTitle {
    margin-bottom: -20px !important;
  }
}

@media (max-width: 370px) {
  .myanmarCitizen span {
    padding: 0px !important;
    width: 42% !important;
  }

  .myanmarCitizen .MuiFormControlLabel-root,
  .passengerType .MuiFormControlLabel-root {
    margin-right: -5px !important;
  }
}

/* new UI for round trip type */
/* MMbus ticket logo at home page of bus */

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  .homeBusDivMobile {
    margin-top: -45% !important;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  .homeBusDivMobile {
    margin-top: -85% !important;
  }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
  .homeBusDivMobile {
    margin-top: -100% !important;
  }
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) {
  .homeBusDivMobile {
    margin-top: -95% !important;
  }
}

/* Note 3 */
@media only screen and (min-device-width: 400px) and (max-device-height: 700px) {
  .homeBusDivMobile {
    margin-top: -100% !important;
  }
}

/* Galaxy Note 3 */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) {
  .homeBusDivMobile {
    margin-top: -60% !important;
  }
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-height: 1024px) {
  .homeBusDivMobile {
    margin-top: -180% !important;
  }
}

/* MMbus ticket logo at home page of bus */
/* new design for language flag */
@media (max-width: 780px) {
  .react-custom-flag-select__select__options-item___3_6R4.react-custom-flag-select__active___2SYE9 {
    background-color: #fff !important;
    color: #00aeef !important;
  }

  .mobileFlagOptionListContainer img,
  .react-custom-flag-select__select__dropdown-flag___4-6D7 img {
    width: 25px !important;
  }

  #homeFlag {
    width: 76px !important;
    float: right !important;
    font-size: 14px !important;
    margin-left: 0 !important;
    margin-top: 0px !important;
  }

  #areaCode {
    width: 100% !important;
    float: left !important;
    //border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .react-custom-flag-select__select__options-container___1TPJG.react-custom-flag-select__show___1cXxR {
    width: 100% !important;
    margin-top: 10% !important;
  }

  .react-custom-flag-select__select__dropdown-icon___3KppP.react-custom-flag-select__showArrow___2E92p:before {
    border-color: #000 transparent !important;
  }

  .react-custom-flag-select__select__dropdown-name___TxNpJ {
    justify-content: left !important;
  }

  .react-custom-flag-select__select__button___15AP2 {
    outline: none !important;
  }

  .react-custom-flag-select__select__options-item___3_6R4 {
    padding: 5px !important;
  }

  .react-custom-flag-select__select__selector___1JJ_x {
    padding: 2px 5px 2px 5px !important;
  }

  .react-custom-flag-select__select__dropdown-name___TxNpJ
    react-custom-flag-select__ellipsis___1ZnQ3 {
    font-size: 14px !important;
    font-family: Roboto !important;
  }

  .react-custom-flag-select__select__dropdown-name___TxNpJ {
    margin-left: 5px !important;
  }

  .mobileFlagWrapper {
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 20px;
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25),
      0 1px 3px -1px rgba(0, 0, 0, 0.3);
  }

  .react-custom-flag-select__ellipsis___1ZnQ3 {
    margin-left: -2px !important;
  }

  .react-custom-flag-select__ellipsis___1ZnQ3 div {
    font-size: 14px !important;
    font-family: Roboto !important;
  }

  .mobileFlagOptionList img {
    margin-left: -3px !important;
    margin-right: 4px !important;
  }

  .mobileFlagOptionListContainer div:nth-child(1) {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .mobileFlagOptionListContainer div:nth-child(2) {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .react-custom-flag-select__select__dropdown-name___TxNpJ div {
    margin-left: 5px !important;
    margin-right: -2px !important;
  }

  .progressbar {
    width: 90% !important;
    justify-content: left !important;
    float: left !important;
  }

  .language-select {
    // width: 76px !important;
    max-width: initial !important;
    box-sizing: initial !important;
    height: auto;
  }
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  #homeFlag {
    width: 26% !important;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  #homeFlag {
    width: 22% !important;
  }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) {
  #homeFlag {
    width: 20% !important;
  }
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) {
  #homeFlag {
    width: 76px !important;
  }
}

/* Note 3 */
@media only screen and (min-device-width: 400px) and (max-device-height: 700px) {
  #homeFlag {
    width: 21% !important;
  }
}

/* Galaxy Note 3 */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) {
  #homeFlag {
    width: 22% !important;
  }
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-height: 1024px) {
  #homeFlag {
    width: 12% !important;
  }
}

/* new design for language flag */
/* new UI design for depart flight div at flight result page */
@media (max-width: 780px) {
  .departFlightMobileDiv img {
    width: 30px !important;
    float: left !important;
  }

  .departFlightMobileDiv span {
    line-height: 25px;
  }

  .departFlightMobileText {
    width: 100%;
    border: 1px solid #00aeef;
    padding: 3px 7px;
    font-size: 12px;
    color: #00aeef;
  }

  .departFlightMobileArrow {
    width: 50% !important;
  }

  .departFlightMobileTime {
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .departFlightMobileDate {
    font-size: 12px !important;
  }

  .departFlightMobileTimeDiv {
    clear: both !important;
  }

  .departFlightMobileCity {
    font-size: 12px !important;
    color: #7f91a8 !important;
  }

  .departFlightMobileAirline {
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 20px !important;
  }

  .departFlightMobileClass {
    font-size: 12px !important;
    color: #7f91a8 !important;
  }

  .departFlightMobileAirlineDiv {
    margin-top: 5px !important;
  }

  .departFlightMobileAirlineLogo {
    margin-top: -3px !important;
  }

  .departFlightMobileAirlineLogoDiv img {
    margin-right: 5px !important;
  }

  .departFlightMobileDivLogo {
    text-align: center !important;
  }

  .departFlightMobileDivLogo img {
    float: none !important;
    margin-bottom: -8px !important;
  }
}

/* new UI design for depart flight div at flight result page */
/* new UI for flight details of customer information page */
@media only screen and (max-device-width: 780px) {
  .flightDetailBox {
    margin-bottom: -10px !important;
  }

  .flightDetailBoxReturnDiv {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom: 1px dotted #e6e7e8 !important;
  }

  .flightDetailBoxLowerDiv {
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
  }

  .custDetailTitle {
    border-bottom: none !important;
  }

  .custDetailTitleMobile {
    margin-top: -8px !important;
  }

  .flightDetailTitleMobile {
    border-top: 1px dotted #e6e7e8 !important;
    border-bottom: none !important;
  }

  .flightDetailTitleMobile img {
    width: 60% !important;
  }

  .flightDetailIconMobile {
    float: left !important;
    text-align: center !important;
    margin-top: -5px !important;
  }

  .flightDetailIconMobile img {
    margin-bottom: -8px !important;
    width: 50% !important;
  }

  .flightDetailRouteMobile {
    float: left !important;
    padding-left: 10px !important;
  }

  .flightDetailRouteNameMobile span {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .flightDetailRouteDateMobile span {
    font-size: 12px !important;
    line-height: 37px !important;
  }

  .flightDetailNameMobile img {
    width: 12% !important;
    float: left !important;
  }

  .flightDetailNameMobile span {
    font-size: 14px !important;
    margin-left: 5px !important;
    float: left !important;
    line-height: 32px !important;
    font-weight: bold !important;
  }

  .flightDetailClockMobile {
    float: left !important;
    font-size: 14px !important;
    text-align: right !important;
  }

  .flightDetailStatusMobile {
    float: left !important;
    text-align: center !important;
  }

  .flightDetailStatusMobile img {
    margin-right: 10px !important;
  }

  .flightDetailDepartCityMobile {
    float: left !important;
    font-size: 14px !important;
  }

  .returnflightDurationMobile {
    border-bottom: 1px dotted #e6e7e8 !important;
  }

  .flightDetailDepartAirportMobile {
    font-size: 10px !important;
  }

  .flightDetailDepartMiddleMobile {
    clear: both !important;
    width: 100% !important;
    margin: 35px auto 0 auto !important;
  }

  .departurePoint {
    height: 8px;
    width: 8px;
    border: 1px solid #00aeef;
    border-radius: 50%;
    display: inline-block;
  }

  .arrivalPoint {
    height: 10px;
    width: 10px;
    background-color: #00aeef;
    border-radius: 50%;
    display: inline-block;
  }

  .routeLine {
    border-left: 2px solid #e6e7e8;
    height: 40px;
    position: absolute;
    margin-top: -18px !important;
    margin-left: -1px !important;
  }

  .flightDetailDurationMobile {
    font-size: 12px !important;
  }

  .oneWidthMobileDiv {
    min-width: 8.33% !important;
    margin-left: 20px !important;
  }

  .tenWidthMobileDiv {
    min-width: 8.33% !important;
    margin-left: 5px !important;
  }

  .continueBox {
    background: none !important;
    border: none !important;
  }

  .flightMobileAirportName {
    font-weight: 500 !important;
  }
}

@media only screen and (max-device-width: 320px) {
  .flightDetailNameMobile span {
    font-size: 12px !important;
    line-height: 23px !important;
  }

  .flightDetailDurationMobile {
    font-size: 11px !important;
  }
}

@media only screen and (min-device-width: 360px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }
}

@media only screen and (min-device-width: 375px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }
}

@media only screen and (min-device-width: 400px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }

  .flightDetailIconMobile img {
    width: 50% !important;
  }
}

@media only screen and (min-device-width: 414px) {
  .flightDetailIconMobile {
    margin-top: -10px !important;
  }

  .flightDetailIconMobile img {
    width: 45% !important;
  }
}

/* new UI for flight details of customer information page */
@media only screen and (max-device-width: 780px) {
  #MobilePassengerType .react-numeric-input b {
    margin-top: -10px !important;
    width: 40px !important;
    height: 40px !important;
    background-size: cover !important;
    border: none !important;
  }

  #MobilePassengerType .react-numeric-input b:nth-child(3) {
    background-image: url(https://images.ctfassets.net/7rpik7r2pwv0/6rWH8SPYQ5xbHVLPTybjrE/d7f978c43cbc4e73e6de4d7dab0a39e5/passenger-picker-decrease-icon.png) !important;
  }

  #MobilePassengerType .react-numeric-input b:nth-child(2) {
    background-image: url(https://images.ctfassets.net/7rpik7r2pwv0/32II2GYDbZhSSdSPNDB44q/c7aedd2febdec5d25cfe248252db1ddb/passenger-picker-increase-icon.png) !important;
  }

  .chooseAge .numeric-counter {
    width: 40px !important;
    margin-top: -8px !important;
    height: 40px !important;
  }

  #MobilePassengerType .react-numeric-input {
    width: 45% !important;
  }
}

@media (max-width: 780px) {
  .btnBlueSearch button {
    height: 60px !important;
  }

  #fl-home-btn-search span {
    font-weight: 500 !important;
  }

  .BusCenterInfo {
    display: none !important;
  }
}

.bookedSeat span {
  color: #ffffff !important;
}

.avaText {
  color: #000000 !important;
}

.selectText {
  color: #ffffff !important;
}

@media (max-width: 780px) {
  .busMobileMyanmarCitizenLable {
    font-weight: 700 !important;
  }

  #bs-home-btn-search span {
    font-weight: 500 !important;
  }

  #mobileBusHomeSearchBox {
    margin-top: 60px !important;
    margin-bottom: 0 !important;
  }

  .busSearchMobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .busSearchMobile button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  #bgBus .homeContainer {
    height: auto !important;
  }

  #bgBus .btnBlueSearch {
    margin-top: -20px !important;
  }

  #bgBus .home-info-card-2 {
    margin-top: 60px;
  }

  #bgBus {
    .myanmarCitizen {
      border-radius: 10px !important;

      .MuiFormGroup-row {
        border-radius: 10px !important;
      }
    }
  }

  .bgBus {
    background-size: 100% 60% !important;
    background-position: top !important;
    //background-color: #000000 !important;
    height: auto !important;
    min-height: auto !important;
  }

  .mobileBusDefaultText {
    color: #a5a7a6 !important;
  }

  .mmBusLogo span {
    font-size: 10px !important;
  }

  .mmBusLogo img {
    width: 50px !important;
  }

  .airportList {
    color: #000000 !important;
  }

  .airportListTitle {
    color: #000;
    font-weight: bold;
    padding: 10px 15px;
    line-height: 36px;
    font-size: 18px;
  }

  .airportList span {
    //color: #a5a7a6 !important;
  }

  .airportListGroup {
    margin-top: 10px !important;
  }

  .noResult {
    padding: 10px 15px;
  }

  .mmBusLogo span {
    line-height: 50px !important;
    margin-right: 5px !important;
  }

  .mobile-from-to label {
    width: 20% !important;
  }

  #mobileBusHomeSearchBox .myanmarCitizen {
    height: 40px !important;
    //margin-top: -75px !important;
  }

  #mobileBusHomeSearchBox .popupSearch .MuiTextField-root {
    width: 93% !important;
    left: -5px !important;
  }

  #mobileBusHomeSearchBox .popupSearch .MuiButton-text {
    margin-left: -12px;
  }

  #mobileBusHomeSearchBox .popupSearch .MuiInputBase-root {
    padding: 0 !important;
  }

  #mobileBusHomeSearchBox .popupSearch .searchTitle {
    left: 0 !important;
  }

  #mobileBusHomeSearchBox .airportList {
    font-size: 16px !important;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  #mobileBusHomeSearchBox .myanmarCitizen span {
    font-size: 15px !important;
  }

  .mobile-from-to label {
    width: 25% !important;
  }

  #mobileBusHomeSearchBox .myanmarCitizen {
    line-height: 50px !important;
  }

  #mobileBusHomeSearchBox .myanmarCitizen label {
    margin-top: 13px;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  #mobileBusHomeSearchBox .myanmarCitizen label {
    margin-top: 8px;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .bgBus {
    height: 80vh !important;
    min-height: 80vh;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .flag-select {
    display: none !important;
  }
}

@media (max-width: 360px) and (max-height: 640px) {
  #mobileBusHomeSearchBox .myanmarCitizen {
    line-height: 50px !important;
  }
}

@media (max-width: 780px) {
  #gorgias-chat-messenger-button {
    opacity: 0;
  }
  .myanmarCitizen span {
    width: 50% !important;
  }

  .departfrom {
    padding: 0 11px !important;
    height: 44px !important;
    //height: 22px !important;
  }

  .flightDepartFrom {
    height: 33px !important;
  }

  .carPassenger {
    height: 22px !important;
  }

  .btnEditSearchMobile {
    margin-left: -7px !important;
    margin-top: 6px !important;
  }

  .busSelectMobile h4 {
    font-weight: 200 !important;
  }

  .btnContinue {
    height: 60px !important;
  }

  .mobileBusSeatHeaderText {
    width: 91% !important;
    margin: 0 auto !important;
    min-height: 35px !important;
    margin-bottom: 10px !important;
  }

  .mobileBusSeatHeaderText span {
    font-size: 14px;
    color: #ffffff !important;
  }

  .busSeatListBullet {
    margin: 0px 5px !important;
    font-size: 20px !important;
    margin-top: -2px !important;
  }

  #mobileBusPhonePrefix {
    margin-top: 10px !important;
  }

  #mobileBusPhonePrefix .flag-dropdown {
    border: none !important;
  }
  #mobileBusPhonePrefix .react-tel-input {
    margin-top: 8px;
  }
  .contactMobileBus .mobileNumberDiv label {
    margin-left: -55px !important;
  }

  #mobileBusPhonePrefix .selected-flag {
    margin-left: -5px !important;
  }

  .contactMobileBus .mobileNumberDiv {
    width: 75% !important;
  }

  .mobileConfirmBtn {
    height: 37px !important;
  }

  .busMobileMyanmarCitizenLable {
    padding-left: 10px !important;
  }

  .textAlignLeft {
    text-align: left !important;
  }

  .textAlignRight {
    text-align: right !important;
  }

  .mobileBusConfirmButton {
    height: 45px !important;
  }

  .imgOpeartor {
    float: none !important;
    margin-left: 0 !important;
  }

  #payment-input-cardHolderName {
    min-height: 21px !important;
    font-size: 16px !important;
    color: #424770 !important;
  }

  .mobileBusPayCounter {
    background: #f4f3f4 !important;
  }

  .paymentPage .contactInfo {
    padding: 10px 20px !important;
  }

  .mobileBusPaymentTypeBox .title {
    margin-left: 0 !important;
  }

  .mobileBusCardBox {
    border: 1px solid #e6e7e8 !important;
    box-shadow: none !important;
  }

  .mobileBusConfirmationDateText {
    font-size: 12px !important;
  }

  .mobileBusConfirmationClassText {
    margin: 5px 0 !important;
  }
}

#bgBus .CalendarDay__default.CalendarDay__today {
  color: #000000;
  border: none !important;
  border-radius: 6px !important;
}

#bgBus .CalendarDay__default.CalendarDay__today:hover {
  color: #ffffff !important;
}

#bgBus .CalendarDay__selected {
  color: #ffffff !important;
  border: none !important;
  border-radius: 6px !important;
  outline: none !important;
}

#bgBus .CalendarDay__default.CalendarDay__today {
  color: #000000;
  border: none !important;
  border-radius: 6px !important;
}

#bgBus .CalendarDay__default.CalendarDay__today:hover {
  color: #ffffff !important;
}

#bgBus .CalendarDay__selected {
  color: #ffffff !important;
  border: none !important;
  border-radius: 6px !important;
  outline: none !important;
}

@media (max-width: 780px) {
  .oneWayLbl {
    text-align: right;
    font-size: 14px;
  }

  .fieldLbl.oneWayLbl {
    line-height: 37px !important;
  }

  .language-select {
    margin-top: 0px !important;
  }
  .language-select.language-select-b2b {
    margin-top: 15px !important;
  }

  .progressbar li {
    min-width: 60px !important;
  }

  .progressbar li:before {
    margin-top: 3px !important;
  }
}

@media (max-width: 320px) {
  .search {
    height: 270px !important;
  }
}

.menuBlueDiv {
  background-color: #f0f0f0 !important;
  border-radius: 12px;
  float: left;
  padding: 20px 0px;
  overflow: hidden;
  width: 47%;
  margin: auto 5px;
  text-align: center;

  img {
    width: 45px;
  }

  span {
    color: #050302;
    font-weight: 400;
    font-size: 18px;
    padding-left: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -38px;
  }
}

.activeMenu .menuBlueDiv {
  background-color: #c9eaff !important;

  span {
    font-weight: 600 !important;
  }
}

.homeMenuDiv {
  margin-top: -60px !important;
}

@media (max-width: 360px) {
  .menuBlueDiv {
    width: 46.5% !important;
  }

  #bgHotel {
    .search .fieldLbl,
    .mobileHotelDestination label span {
      line-height: 19px !important;
    }

    .mobileHotelDestination.departfrom {
      label {
        width: 100px !important;

        span:last-child {
          display: block;
        }
      }

      .MuiTextField-root {
        margin-left: 10px;
        margin-top: 0px !important;
      }
    }
  }
}

.myanmarCitizen .Mui-disabled svg {
  color: #d1d1d1 !important;
}

.emailAddressLoginPopoverText {
  font-size: 14px;
  .signedName {
    font-weight: 700;
    color: #0d87dd;
  }
}

.domesticInternationalTabsBtnContainer {
  margin: 12px auto 10px auto;
  width: 90%;
  display: flex;
  justify-content: space-between;

  .domesticInternationalTabsBtn {
    width: 48%;
    font-size: 16px;
    padding: 16px;
    border-radius: 10px;
    border: none;
    background-color: #ebecf0;
  }

  .domesticInternationalTabsBtnActive {
    background-color: #4dabe9 !important;
    color: white !important;
  }
}

// For Covid Alert Banner
.bgHotel {
  @media (min-width: 781px) {
    margin-top: 35px;
  }
}

// For Contact Us Logo from footer
.contactUSLogoFooter {
  @media (min-width: 786px) {
    margin-top: -42px;
  }
}

// Become a partner "Find More Out" button
.becomePartnerButton {
  color: white;
  padding: 13px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  margin-left: 17px;

  @media (max-width: 784px) {
    margin-left: 0;
    margin-bottom: 26px;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    margin-left: 12px;
  }
}
